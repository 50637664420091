import React from "react";
import { Box, Typography } from "@mui/material";
// import ImageComp from "../ImageComp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { SuccessfulSVG } from "../../components/globalComponents/constants";
import theme from "../../theme";
import ButtonComp from "../../components/globalComponents/ButtonComp";
import { useLocation, useNavigate } from "react-router-dom";
const SuccessfulPaymentResponsePage = () => {
  const navigate = useNavigate();
  let location = useLocation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        marginBottom: "24px",
        alignItems: "center",
      }}
    >
      <SuccessfulSVG />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "12px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="black24">Successfully</Typography>
          <CheckCircleIcon
            sx={{ color: theme.palette.primary.main, padding: "5px" }}
          />
        </Box>
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Congratulation! Your Payment has been successful
        </Typography>
        <ButtonComp
          label={"Go Back"}
          click={() => {
            //   navigate(location.state);
            navigate(location.state || "/feed");
          }}
        />
      </Box>
    </Box>
  );
};

export default SuccessfulPaymentResponsePage;
